<template>
  <div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <el-form label-position="top" :model="brandData" :rules="brandRules" ref="brandData">
          <el-row :gutter="20" class="flex flex-center-text">
            <el-col :span="30">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="brand_monName">
                        <el-input v-model="brandData.brand_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="brand_engName">
                      <el-input v-model="brandData.brand_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Лого зураг">
                          <el-upload
                            list-type="picture-card"
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            action=""
                            :limit="1"
                            :file-list="tempImages"
                            :auto-upload="false"
                            :on-change="(file) => getFile(file,'image')"
                            :on-remove="(file) => handleRemove(file, 'image')">
                            <span>250x250</span>
                          </el-upload>
                          <loader :active="isUploading" />
                        </el-form-item>
                      </el-col>
                    <el-col :span="24">
                      <el-form-item label="Нийлүүлэгчийн ID" prop="supplier_brand_id">
                        <el-input v-model="brandData.supplier_brand_id" type="input" placeholder="Улс, түүх"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="Тайлбар" prop="brand_description">
                        <el-input v-model="brandData.brand_description" type="textarea" placeholder="Улс, түүх"></el-input>
                      </el-form-item>
                    </el-col>
                    </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="30" class="float-right">
                <el-button type="success" @click="createBrand('brandData', 'brandRules')"
                >Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { handlers } from '../../../../helpers/custom'
import loader from '../../../../components/common/loader'
import services from '../../../../helpers/services'
import { getSupplierId } from '../../../../utils/auth'
export default {
  components: {
    loader: loader
  },

  computed: {
    errorArray () {
      var errors = []
      if (!this.isNewForm) {
        if (this.brandData.brand_monName.length === 0) {
          errors.push('Монгол нэр оруулна уу')
        }
        if (this.brandData.brand_engName.length === 0) {
          errors.push('Англи нэр оруулна уу')
        }
        if (this.brandData.fee_percent.length === 0) {
          errors.push('Шимтгэлийн хувь оруулна уу')
        }
        if (this.brandData.brand_description.length === 0) {
          errors.push('Тайлбар оруулна уу')
        }
        if (this.brandData.supplier_id.length === 0) {
          errors.push('Нийлүүлэгч оруулна уу')
        }
        if (this.brandData.supplier_brand_id.length === 0) {
          errors.push('Нийлүүлэгч ID оруулна уу')
        }
      }
      return errors
    }
  },

  data () {
    return {
      isNewForm: true,
      tempImages: [],
      isUploading: false,
      loading: false,
      pageStatus: {
        error: false,
        message: ''
      },
      brandData: {
        brand_id: '',
        brand_monName: '',
        fee_percent: '',
        brand_engName: '',
        supplier_id: '',
        brand_description: '',
        brand_logo: '',
        supplier_brand_id: ''
        // user_name: ''
      },
      brandRules: {
        brand_monName: [
          { required: true, message: 'Брэндийн монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'blur' }
        ],
        brand_engName: [
          { required: true, message: 'Брэндийн англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        fee_percent: [
          { required: true, message: 'Шимтгэлийн хувь оруулна уу', trigger: 'blur' }
        ],
        brand_description: [
          { required: true, message: 'Брэндийн тайлбараа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    handleRemove (file, type) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    getFile (file, type) {
      handlers.checkImageSize(file, true, 250, 250).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },

    async createBrand (formName) {
      const supplierId = await getSupplierId()
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.brandData.brand_logo = tempImages
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.brandData.isActive = 'inactive'
          this.brandData.supplier_id = this.$route.query.supplier_id && this.$route.query.supplier_id !== '' ? this.$route.query.supplier_id : supplierId[0]
          services.createBrand(this.brandData).then(response => {
            if (response.status === 'success') {
              localStorage.setItem('brandCount', '1')
              this.alertReporter('Амжилттай', response.message, 'success')
              this.$router.push({ name: 'products' })
            } else {
              this.alertReporter('Алдаа', response.message, 'warning')
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
