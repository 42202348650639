<template>
  <div>
    <el-steps :active="activeStep" align-center finish-status="success" class="mb30">
      <el-step title="Нийлүүлэгч үүсгэх"></el-step>
      <el-step title="Брэндүүд үүсгэх"></el-step>
    </el-steps>
    <div v-if="activeStep === 0">
      <add-supplier @step="steps"/>
    </div>
    <div v-else-if="activeStep === 1">
      <add-brands />
    </div>
  </div>
</template>

<script>
import addSupplier from './components/addSupplier'
import addBrands from './components/addBrands'
export default {
  components: {
    addSupplier,
    addBrands
  },
  data () {
    return {
      activeStep: this.$route.query.activeStep ? JSON.parse(this.$route.query.activeStep) : 0
    }
  },
  methods: {
    steps (value) {
      this.activeStep = value
    }
  }
}
</script>
