<template>
  <div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <el-form label-position="top" :model="supplierData" :rules="supplierRules" ref="supplierData">
          <el-row :gutter="20">
            <el-col :span="30">
              <div class="panel">
              <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="supplier_monName">
                        <el-input v-model="supplierData.supplier_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="supplier_engName">
                      <el-input v-model="supplierData.supplier_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <!-- <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Байгууллага">
                        <el-checkbox></el-checkbox>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Хувь хүн">
                        <el-checkbox></el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-row> -->
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Регистрийн дугаар" prop="supplier_regno">
                        <el-input v-model="supplierData.supplier_regno" placeholder="6183352" :v-on:input="numberValidate()"></el-input>
                      </el-form-item>
                    </el-col>
                      <el-col :span="12">
                        <el-form-item label="Байгууллага">
                          <el-input placeholder="Дижитал аггрегатор" disabled type="text" :value='supplierData.EbarimtSupplier'></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Утасны дугаар" prop="phone">
                          <el-input v-model="supplierData.phone" maxlength="12" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Нэмэлт утасны дугаар">
                          <el-input v-model="supplierData.phone2" maxlength="12" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Имэйл">
                    <el-input v-model="supplierData.supplier_email" type="email" rows="3" placeholder="a.@gmail.com"></el-input>
                  </el-form-item>
                      </el-col>
                    </el-row>
                      <el-form-item label="Хаяг">
                    <el-input v-model="supplierData.address" type="textarea" rows="3" placeholder="ХУД 3-р хороо Гоёо"></el-input>
                  </el-form-item>
                  <div class="" style="width: 100%; height: 400px; display:block">
                    <gmap-map
                      :center="googleMap.center"
                      :zoom="12"
                      :options="googleMap.options"
                      :draggable="false"
                      style="width:100%;  height: 100%; margin-top: 5px;"
                    >
                    <gmap-marker
                      :position="googleMap.location"
                      :clickable="true"
                       @position_changed="changeLocation($event)"
                      :draggable="true"/>
                    </gmap-map>
                  </div>
                </div>
                </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="30" class="float-right">
                <el-button type="success" :disabled="isDisabled" @click="onPressNewProdSupplier(supplierData)
                ">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import services from '../../../../helpers/services'
import { Auth } from 'aws-amplify'
export default {
  created () {
    this.$router.push({ name: 'create', query: { activeStep: 0 } }).catch(() => {})
  },
  data () {
    return {
      user: null,
      isDisabled: false,
      loading: false,
      confirmLeaveForm: false,
      pageStatus: {
        error: false,
        message: ''
      },
      supplierData: {
        is_tax_paid: false,
        isActive: true,
        diamond_code: '',
        package_connetion_check: false,
        ebarimt_type: '',
        brands: [],
        supplier_monName: '',
        supplier_engName: '',
        supplier_regno: '',
        EbarimtSupplier: '',
        phone: '',
        phone2: '',
        address: '',
        location: '47.9174, 106.9137',
        supplier_id: ''
      },

      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },

        location: {
          lat: 47.9174,
          lng: 106.9137
        },

        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },

        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      },

      supplierRules: {
        supplier_monName: [
          { required: true, message: 'Байгууллагын монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_engName: [
          { required: true, message: 'Байгууллагын англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_regno: [
          { required: true, message: 'Байгууллагын регистрийн дугаараа бөглөнө үү', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу', trigger: 'blur' },
          { min: 6, max: 12, message: 'Утасны дугаараа бүрэн оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },
  async mounted () {
    this.user = await Auth.currentAuthenticatedUser()
  },

  methods: {
    numberValidate () {
      if (this.supplierData.supplier_regno.length <= 10) {
        this.checkEbarimt()
      }
    },
    checkEbarimt () {
      services.checkEbarimt(this.supplierData.supplier_regno).then(response => {
        if (response.found === true) {
          this.supplierData.EbarimtSupplier = response.name
        }
      })
    },

    changeLocation (event) {
      this.supplierData.location = event.lat() + ', ' + event.lng()
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },

    async onPressNewProdSupplier (formName) {
      const body = formName
      services.addSupplier(body).then(response => {
        if (response.status === 'success') {
          const supplierId = []
          supplierId.push(response.data.supplier_id)
          Auth.updateUserAttributes(this.user, { 'custom:supplier_ids': JSON.stringify(supplierId) })
          this.alertReporter('Амжилттай', response.message, 'success')
          this.$emit('step', 1)
          this.$router.push({ name: 'create', query: { activeStep: 1, supplier_id: response.data.supplier_id } }).catch(() => {})
        } else {
          this.alertReporter('Алдаа', response.message, 'warning')
        }
      })
    }
  }

}
</script>
